import React, { useState, useEffect } from 'react'
// import "./App.css";

const apiUrl = 'http://www.andreamartines.it/download/libro'

function App() {
  const [libreria, setLibreria] = useState({})

  useEffect(() => {
    read()
  }, [])

  const read = async () => {
    const response = await fetch(`${apiUrl}/read.php`)
    const jsonData = await response.json()
    setLibreria(jsonData)
  }
  const create = async () => {
    let formData = new FormData()
    formData.append('ISBN', document.getElementById('isbn').value)
    formData.append('Titolo', document.getElementById('autore').value)
    formData.append('Autore', document.getElementById('titolo').value)
    formData.append("File", document.getElementById('file').files[0], "titolo.pdf");
    // console.log('vediamo', document.getElementById('file').files)
    // console.log('formData', formData)
    // console.log('JSON.stringify(formData)', JSON.stringify(formData))
    const response = await fetch(`${apiUrl}/create.php`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: formData,
      // body: JSON.stringify({
      //   ISBN: document.getElementById('isbn').value,
      //   Titolo: document.getElementById('autore').value,
      //   Autore: document.getElementById('titolo').value,
      //   // File: document.getElementById('file').files[0],
      // }),
    }).then(
      success => console.log(success) // Handle the success response object
    ).catch(
      error => console.log(error) // Handle the error response object
    );
  }
  const onSubmit = (e) => {
    // console.log('e', e)
    e.preventDefault()
    console.log(document.getElementById('isbn').value)
    // console.log(document.getElementById('file').value)
    console.log(e.target)
    create()
    // const newEmployee = {
    //   id: employees.length + 1,
    //   name,
    //   location,
    //   designation,
    // };
    // addEmployee(newEmployee);
    // history.push("/");
  }

  console.log('libreria', libreria)

  return (
    <div>
      <header>
        <h2>Libri</h2>
      </header>
      <h2>Read</h2>
      <table>
        {libreria.records &&
          libreria.records.map((libro) => {
            return (
              <tr>
                <td>{libro.ISBN}</td>
                <td>{libro.Autore}</td>
                <td>{libro.Titolo}</td>
              </tr>
            )
          })}
      </table>

      <h2>Create</h2>
      <form id="formCreate" onSubmit={onSubmit}>
        <div>
          <label htmlFor="isbn">ISBN</label>
          <input
            id="isbn"
            type="text"
            placeholder="Enter ISBN"
          />
        </div>
        <div>
          <label htmlFor="autore">Autore</label>
          <input
            id="autore"
            type="text"
            placeholder="Enter autore"
          />
        </div>
        <div>
          <label htmlFor="titolo">Titolo</label>
          <input
            id="titolo"
            type="text"
            placeholder="Enter titolo"
          />
        </div>
        <div>
          <label htmlFor="file">File</label>
          <input
            id="file"
            type="file"
            placeholder="Enter file"
          />
        </div>
        <div className="flex items-center justify-between">
          <button>Aggiungi</button>
        </div>
      </form>
    </div>
  )
}

export default App
